import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



const MainImageSkeleton = () => {
    return (

        <div style={{
            position: 'relative',
            width: '100%',
            paddingTop: '66.67%' // This creates a 1.5:1 aspect ratio (100/1.5 = 66.67%)
        }}>
            <Skeleton
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                }}
            />
        </div>


    )
}

export default MainImageSkeleton