import React from 'react'

const Boosted = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={29} height={30} viewBox="0 0 29 30" fill="none">
            <path d="M8 23.0001V17.0751C8 16.7418 8.07933 16.4251 8.238 16.1251C8.39667 15.8251 8.61733 15.5834 8.9 15.4001L10 14.6751C10.1167 16.0751 10.3 17.2668 10.55 18.2501C10.8 19.2334 11.1917 20.3251 11.725 21.5251L8 23.0001ZM13.225 21.0001C12.6417 19.9001 12.2083 18.7334 11.925 17.5001C11.6417 16.2668 11.5 14.9918 11.5 13.6751C11.5 11.5918 11.9127 9.62943 12.738 7.7881C13.5633 5.94676 14.6507 4.55076 16 3.6001C17.35 4.5501 18.4377 5.9461 19.263 7.7881C20.0883 9.6301 20.5007 11.5924 20.5 13.6751C20.5 14.9751 20.3583 16.2378 20.075 17.4631C19.7917 18.6884 19.3583 19.8674 18.775 21.0001H13.225ZM16 14.0001C16.55 14.0001 17.021 13.8044 17.413 13.4131C17.805 13.0218 18.0007 12.5508 18 12.0001C17.9993 11.4494 17.8037 10.9788 17.413 10.5881C17.0223 10.1974 16.5513 10.0014 16 10.0001C15.4487 9.99876 14.978 10.1948 14.588 10.5881C14.198 10.9814 14.002 11.4521 14 12.0001C13.998 12.5481 14.194 13.0191 14.588 13.4131C14.982 13.8071 15.4527 14.0028 16 14.0001ZM24 23.0001L20.275 21.5251C20.8083 20.3251 21.2 19.2334 21.45 18.2501C21.7 17.2668 21.8833 16.0751 22 14.6751L23.1 15.4001C23.3833 15.5834 23.6043 15.8251 23.763 16.1251C23.9217 16.4251 24.0007 16.7418 24 17.0751V23.0001Z" fill="currentColor" />
            <g clipPath="url(#clip0_358_81)">
                <path d="M2.99964 11.6256C2.92273 11.6262 2.84755 11.6028 2.78454 11.5587C2.72154 11.5146 2.67384 11.452 2.64807 11.3795L2.10737 9.89264L0.620729 9.35217C0.548963 9.32553 0.487068 9.27757 0.44336 9.21472C0.399651 9.15188 0.376224 9.07716 0.376224 9.00061C0.376224 8.92406 0.399651 8.84934 0.44336 8.78649C0.487068 8.72365 0.548963 8.67568 0.620729 8.64905L2.1076 8.10858L2.64807 6.6217C2.67471 6.54994 2.72268 6.48804 2.78552 6.44433C2.84837 6.40062 2.92308 6.3772 2.99964 6.3772C3.07619 6.3772 3.1509 6.40062 3.21375 6.44433C3.2766 6.48804 3.32456 6.54994 3.3512 6.6217L3.89167 8.10858L5.37854 8.64905C5.45031 8.67568 5.5122 8.72365 5.55591 8.78649C5.59962 8.84934 5.62305 8.92406 5.62305 9.00061C5.62305 9.07716 5.59962 9.15188 5.55591 9.21472C5.5122 9.27757 5.45031 9.32553 5.37854 9.35217L3.89167 9.89287L3.3512 11.3795C3.32543 11.452 3.27773 11.5146 3.21473 11.5587C3.15172 11.6028 3.07654 11.6262 2.99964 11.6256Z" fill="currentColor" />
            </g>
            <g clipPath="url(#clip1_358_81)">
                <path d="M28.1561 4.65682C28.1569 4.75936 28.1257 4.8596 28.0669 4.94361C28.0081 5.02762 27.9246 5.09122 27.828 5.12557L25.8455 5.84651L25.1248 7.82869C25.0893 7.92438 25.0254 8.00691 24.9416 8.06519C24.8578 8.12346 24.7582 8.1547 24.6561 8.1547C24.554 8.1547 24.4544 8.12346 24.3706 8.06519C24.2868 8.00691 24.2229 7.92438 24.1873 7.82869L23.4667 5.84619L21.4842 5.12557C21.3885 5.09005 21.306 5.0261 21.2477 4.9423C21.1894 4.85851 21.1582 4.75889 21.1582 4.65682C21.1582 4.55475 21.1894 4.45513 21.2477 4.37133C21.306 4.28754 21.3885 4.22359 21.4842 4.18807L23.4667 3.46744L24.1873 1.48494C24.2229 1.38925 24.2868 1.30673 24.3706 1.24845C24.4544 1.19017 24.554 1.15894 24.6561 1.15894C24.7582 1.15894 24.8578 1.19017 24.9416 1.24845C25.0254 1.30673 25.0893 1.38925 25.1248 1.48494L25.8458 3.46744L27.828 4.18807C27.9246 4.22242 28.0081 4.28602 28.0669 4.37003C28.1257 4.45403 28.1569 4.55428 28.1561 4.65682Z" fill="currentColor" />
            </g>
            <g clipPath="url(#clip2_358_81)">
                <path d="M13.3746 26.9999C13.374 26.923 13.3974 26.8478 13.4415 26.7848C13.4856 26.7218 13.5483 26.6741 13.6207 26.6483L15.1076 26.1076L15.6481 24.621C15.6747 24.5492 15.7227 24.4873 15.7855 24.4436C15.8484 24.3999 15.9231 24.3765 15.9996 24.3765C16.0762 24.3765 16.1509 24.3999 16.2137 24.4436C16.2766 24.4873 16.3246 24.5492 16.3512 24.621L16.8917 26.1078L18.3785 26.6483C18.4503 26.675 18.5122 26.7229 18.5559 26.7858C18.5996 26.8486 18.623 26.9233 18.623 26.9999C18.623 27.0764 18.5996 27.1511 18.5559 27.214C18.5122 27.2768 18.4503 27.3248 18.3785 27.3514L16.8917 27.8919L16.3512 29.3788C16.3246 29.4506 16.2766 29.5124 16.2137 29.5562C16.1509 29.5999 16.0762 29.6233 15.9996 29.6233C15.9231 29.6233 15.8484 29.5999 15.7855 29.5562C15.7227 29.5124 15.6747 29.4506 15.6481 29.3788L15.1074 27.8919L13.6207 27.3514C13.5483 27.3257 13.4856 27.278 13.4415 27.215C13.3974 27.152 13.374 27.0768 13.3746 26.9999Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_358_81">
                    <rect width={6} height={6} fill="white" transform="translate(6 6.00049) rotate(90)" />
                </clipPath>
                <clipPath id="clip1_358_81">
                    <rect width={8} height={8} fill="white" transform="translate(20.6562 0.656738)" />
                </clipPath>
                <clipPath id="clip2_358_81">
                    <rect width={6} height={6} fill="white" transform="translate(19 30) rotate(-180)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Boosted
